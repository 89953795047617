export const throttle = (callback: Function, limit: number) => {
  let wait = false;
  let cachedCall: Function;
  let cachedArgs: any[];
  return (...args: any[]) => {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
        if (cachedCall && cachedArgs) {
          cachedCall(...cachedArgs);
          cachedCall = undefined;
          cachedArgs = undefined;
        }
      }, limit);
    } else {
      cachedCall = callback;
      cachedArgs = args;
    }
  };
};
