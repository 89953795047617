import {
  DailyInputSettings,
  DailyInputVideoProcessorSettings,
} from '@daily-co/daily-js';
import {
  useDaily,
  useDailyEvent,
  useInputSettings,
} from '@daily-co/daily-react';
import deepEqual from 'fast-deep-equal';
import { useAtomValue } from 'jotai';
import { atomWithStorage, useAtomCallback } from 'jotai/utils';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useCallback, useState } from 'react';

import { useCallConfig } from './useCallConfig';

type Effect =
  | 'none'
  | 'soft-blur'
  | 'strong-blur'
  | 'vb-coffeeshop'
  | 'vb-forest'
  | 'vb-hills'
  | 'vb-library'
  | 'vb-lounge'
  | 'vb-ocean'
  | 'vb-office'
  | 'vb-palms'
  | 'vb-rollercoaster'
  | 'custom';

const sortedEffects: Effect[] = [
  'none',
  'soft-blur',
  'strong-blur',
  'vb-coffeeshop',
  'vb-forest',
  'vb-hills',
  'vb-library',
  'vb-lounge',
  'vb-ocean',
  'vb-office',
  'vb-palms',
  'vb-rollercoaster',
];

const COOKIE_KEY_DEPRECATED = 'bg-effect';
const COOKIE_KEY = `__Host-${COOKIE_KEY_DEPRECATED}`;

const cookieStorage = {
  getItem: (key: string): Effect => {
    let storedEffect = Cookies.get(key) as Effect;
    if (sortedEffects.includes(storedEffect)) return storedEffect;
    storedEffect = Cookies.get(COOKIE_KEY_DEPRECATED) as Effect;
    if (sortedEffects.includes(storedEffect)) {
      Cookies.remove(COOKIE_KEY_DEPRECATED, { sameSite: 'none', secure: true });
      Cookies.set(key, storedEffect, {
        expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
      return storedEffect;
    }
    return 'none';
  },
  setItem: (key: string, newValue: Effect) => {
    if (newValue === 'none') {
      Cookies.remove(key, {
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
    } else {
      Cookies.set(key, newValue, {
        expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
        sameSite: 'none',
        secure: true,
        partitioned: true,
      });
    }
  },
  removeItem: (key: string) => {
    Cookies.remove(key, { sameSite: 'none', secure: true, partitioned: true });
  },
};

const selectedBackgroundEffectAtom = atomWithStorage<Effect>(
  COOKIE_KEY,
  'none',
  cookieStorage
);

export const useBackgroundEffect = () => {
  const { enableVideoProcessingUI } = useCallConfig();
  const currentEffect = useAtomValue(selectedBackgroundEffectAtom);
  const { assetPrefix } = getConfig().publicRuntimeConfig;
  const daily = useDaily();
  const [loading, setLoading] = useState(false);

  const { inputSettings, updateInputSettings } = useInputSettings({
    onInputSettingsUpdated: useAtomCallback(
      useCallback(
        (_get, set, ev) => {
          if (!enableVideoProcessingUI) return;
          let newEffect: Effect = 'none';

          const { video } = ev.inputSettings ?? {};
          if (video?.processor?.type === 'background-blur') {
            const { config } = video.processor;
            const level = config?.strength ?? 0;
            if (level === 0) {
              newEffect = 'none';
            } else if (level < 0.5) {
              newEffect = 'soft-blur';
            } else {
              newEffect = 'strong-blur';
            }
            set(selectedBackgroundEffectAtom, newEffect);
          } else if (video?.processor?.type === 'background-image') {
            const { config } = video.processor;
            if (config.source) {
              if (config.source instanceof ArrayBuffer) {
                set(selectedBackgroundEffectAtom, 'custom');
              } else {
                const url = new URL(config.source as string);
                const match = url.pathname.match(
                  new RegExp(
                    sortedEffects.filter((fx) => fx.startsWith('vb-')).join('|')
                  )
                );
                if (match) {
                  newEffect = match[0] as Effect;
                  set(selectedBackgroundEffectAtom, newEffect);
                } else {
                  set(selectedBackgroundEffectAtom, 'custom');
                }
              }
            }
          } else {
            set(selectedBackgroundEffectAtom, 'none');
          }
          setLoading(false);
        },
        [enableVideoProcessingUI]
      )
    ),
    onError: useCallback(() => {
      setLoading(false);
    }, []),
  });

  const selectEffect = useCallback(
    (effect: Effect) => {
      try {
        let type: DailyInputVideoProcessorSettings['type'] = 'none';
        let blurLevel = 0;
        if (effect.endsWith('-blur')) {
          type = 'background-blur';
          switch (effect) {
            case 'strong-blur':
              blurLevel = 1;
              break;
            case 'soft-blur':
              blurLevel = 0.2;
              break;
          }
        } else if (effect.startsWith('vb-')) {
          type = 'background-image';
        }

        let settings: DailyInputSettings | null = null;

        switch (type) {
          case 'none':
            settings = {
              video: {
                processor: {
                  type,
                },
              },
            };
            break;
          case 'background-blur':
            settings = {
              video: {
                processor: {
                  type,
                  config: {
                    strength: blurLevel,
                  },
                },
              },
            };
            break;
          case 'background-image':
            const { hostname, port, protocol } = window.location;
            // Check if assetPrefix already contains FQDN
            const prefix = assetPrefix.match(/^https?\:\/\//)
              ? assetPrefix
              : `${protocol}//${hostname}:${port}${assetPrefix}`;
            settings = {
              video: {
                processor: {
                  type,
                  config: {
                    source: `${prefix}/backgrounds/${effect}.jpg`,
                  },
                },
              },
            };
            break;
        }

        if (
          deepEqual(inputSettings, settings) ||
          (deepEqual(inputSettings, {}) && effect === 'none')
        )
          return;

        setLoading(true);
        updateInputSettings(settings);
      } catch {}
    },
    [assetPrefix, inputSettings, updateInputSettings]
  );

  const selectCustomBackground = useCallback(
    async (data: string | ArrayBuffer) => {
      setLoading(true);
      await updateInputSettings({
        video: {
          processor: {
            type: 'background-image',
            config: {
              source: data,
            },
          },
        },
      });
    },
    [updateInputSettings]
  );

  useDailyEvent(
    'started-camera',
    useCallback(async () => {
      if (!enableVideoProcessingUI || !daily) return;
      // Read currently applied inputSettings in daily-js
      const inputSettings = await daily.getInputSettings();
      // Apply prebuilt bg-effect cookie if and only if no input settings are
      // currently applied in daily-js. This is to prevent overriding settings
      // provided in join(), createFrame(), createCallObject() or by calling
      // updateInputSettings() directly. Without any such settings applied, the
      // inputSettings object will be empty {}.
      if (currentEffect && !inputSettings?.video?.processor) {
        if (currentEffect !== 'none') setLoading(true);
        selectEffect(currentEffect);
      }
    }, [currentEffect, daily, enableVideoProcessingUI, selectEffect])
  );

  return {
    currentEffect,
    effects: sortedEffects,
    loading,
    selectCustomBackground,
    selectEffect,
  };
};
