import Daily from '@daily-co/daily-js';
import { captureException } from '@sentry/browser';

/**
 * Use this method to capture exceptions due to play() failing for an <audio> element.
 * @param e The thrown exception, as caught in the catch statement.
 * @param audio The HTMLAudioElement which was attempted to play().
 * @param localSessionId The sessionId as returned from useLocalSessionId().
 * @param extra Optional additional extra data to be captured in Sentry. Should contain context information, e.g. which component caught this exception.
 */
export const captureAudioPlayException = (
  e: any,
  audio: HTMLAudioElement,
  localSessionId: string,
  extra: Record<string, unknown> = {}
) => {
  const audioTrack = (audio.srcObject as MediaStream)?.getAudioTracks()?.[0];
  captureException(e, {
    extra: {
      audioElement: audio.outerHTML,
      dataset: { ...audio.dataset },
      localSessionId: localSessionId,
      mediaStreamTrack: {
        // contentHint not available in Next.js types?
        // @ts-ignore
        contentHint: audioTrack?.contentHint,
        enabled: audioTrack?.enabled,
        id: audioTrack?.id,
        kind: audioTrack?.kind,
        label: audioTrack?.label,
        muted: audioTrack?.muted,
        readyState: audioTrack?.readyState,
      },
      ...extra,
    },
  });
};

/**
 * Use this method to capture InvalidStateError, because Sentry doesn't contain any helpful information to further debug this error.
 * @param e The thrown exception, as caught in the catch statement.
 * @param extra Optional additional extra data to be captured in Sentry. Should contain context information, e.g. which component caught this exception.
 */
export const captureInvalidStateError = (
  e: Error,
  extra: Record<string, unknown> = {}
) => {
  const callObject = Daily.getCallInstance();

  const participants = Object.values(callObject?.participants() ?? {}).reduce(
    (obj, p) => {
      obj[p.local ? 'local' : p.session_id] = {
        audioTrack: {
          state: p.tracks.audio.state,
          subscribed: p.tracks.audio.subscribed,
        },
        screenAudioTrack: {
          state: p.tracks.screenAudio.state,
          subscribed: p.tracks.screenAudio.subscribed,
        },
        screenVideoTrack: {
          state: p.tracks.screenVideo.state,
          subscribed: p.tracks.screenVideo.subscribed,
        },
        videoTrack: {
          state: p.tracks.video.state,
          subscribed: p.tracks.video.subscribed,
        },
      };
      return obj;
    },
    {}
  );
  const audioCtx = window['_daily']['audioContext'];

  captureException(e, {
    extra: {
      message: e.message,
      name: e.name,
      stack: e.stack,
      userAgent: navigator.userAgent,
      date: new Date(),
      audioContext: {
        baseLatency: audioCtx?.baseLatency,
        currentTime: audioCtx?.currentTime,
        outputLatency: audioCtx?.outputLatency,
        sampleRate: audioCtx?.sampleRate,
        sinkId: audioCtx?.sinkId,
        state: audioCtx?.state,
      },
      participants,
      ...extra,
    },
  });
};
