import { useEffect } from 'react';

import { ChunkLoader } from './ChunkLoader';

type Props = React.ComponentProps<typeof ChunkLoader>;

export const AutoChunkLoader: React.FC<Props> = ({
  error,
  retry,
  timedOut,
}) => {
  useEffect(() => {
    if (!error && !timedOut) return;
    const interval = setInterval(retry, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [error, retry, timedOut]);

  return null;
};
