/**
 * Checks if the given media element is currently in a playing state.
 * @param element The media element to check.
 * @returns boolean
 */
export const isMediaElementPlaying = (
  element: HTMLAudioElement | HTMLVideoElement
) =>
  element.currentTime > 0 &&
  !element.paused &&
  !element.ended &&
  element.readyState > element.HAVE_CURRENT_DATA;
