import * as Sentry from '@sentry/react';

if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT) {
  const sampleRate = ['development', 'vercel-preview'].includes(
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
  )
    ? 1.0
    : 0.1;
  Sentry.init({
    dsn: 'https://468e374d8f614f9691bb3194f97efd42@o77906.ingest.sentry.io/5728081',
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: sampleRate,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // See: https://stackoverflow.com/a/50387233
      'ResizeObserver loop limit exceeded',
      'Cancel rendering route',
    ],
    allowUrls: [/(\.staging)?\.daily\.co/, /\.vercel\.app/, /localhost/],
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category.includes('redux')) return null;
      /**
       * In case the clicked element is inside a button with an id,
       * update breadcrumb message to include the button id instead of the selector path.
       */
      if (
        breadcrumb.category.includes('ui.click') &&
        hint.event.target.closest('button[id]')
      ) {
        const btn = hint.event.target.closest('button[id]');
        /**
         * Filter out jsx- classNames, as they're autogenerated anyway,
         * but include all other classNames.
         */
        const classList = [...btn.classList].filter(
          (c) => !c.startsWith('jsx-')
        );
        breadcrumb.message = `button#${btn.id}${
          classList.length ? `.${classList.join('.')}` : ''
        }`;
      }
      return breadcrumb;
    },
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  });
}
