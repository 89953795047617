import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';

import { Layout } from './Layout';

export const LoadingScreen: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Layout>
      <div>
        <LoadingSpinner />
      </div>
      <style jsx>{`
        div {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
        }
      `}</style>
    </Layout>
  );
};
