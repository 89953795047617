import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyThemeConfig, SidebarView } from '@daily-co/daily-js';
import {
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import IframeDriverMessageChannel from '/contexts/IframeDriverMessageChannel';
import { useCallConfig } from '/hooks/useCallConfig';
import { useCustomIntegrations } from '/hooks/useCustomIntegrations';
import { useSidebarView } from '/lib/state/core';
import {
  useCustomTrayButtons,
  useEnableJoinSound,
  useIsFullscreen,
  useShowLocalVideo,
  useShowNames,
  useShowParticipantsBar,
} from '/lib/state/custom';
import { usePreferredViewMode } from '/lib/state/layout';
import { convertDailyThemeColors } from '/lib/theme';

import { useIframeCustomIntegrations } from './useIframeCustomIntegrations';

export const useIframeUIControlHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const [, setCustomTrayButtons] = useCustomTrayButtons();
  const [, setEnableJoinSound] = useEnableJoinSound();
  const [, setIsFullscreen] = useIsFullscreen();
  const [showLocalVideo, setShowLocalVideo] = useShowLocalVideo();
  const [, setShowNames] = useShowNames();
  const [, setShowParticipantsBar] = useShowParticipantsBar();
  const [sidebarView, setSidebarView] = useSidebarView();
  const { setDarkColors, setLightColors } = useTheme();
  const [preferredViewMode, setPreferredViewMode] = usePreferredViewMode();
  const [activeSpeakerMode, setActiveSpeakerMode] = useState(false);
  const { enableBreakoutRooms, enableChat, enableNetworkUI, enablePeopleUI } =
    useCallConfig();
  const { sidebarIntegrations } = useCustomIntegrations();

  const handleDriverSetActiveSpeakerModeMsg = useCallback(
    (msg: any) => {
      // Map activeSpeakerMode to preferredViewMode
      setPreferredViewMode(msg.enabled ? 'speaker' : 'grid');
      // Swallow msg (don't forward to call machine)
    },
    [setPreferredViewMode]
  );

  const handleDriverShowLocalVideoMsg = useCallback(
    (msg: any) => {
      // Map showLocalVideo (property) to showLocalVideo (state)
      setShowLocalVideo(msg?.show);
      // Swallow msg (don't forward to call machine)
    },
    [setShowLocalVideo]
  );

  const handleDriverShowParticipantsBarMsg = useCallback(
    (msg: any) => {
      // Map showParticipantsBar (property) to showParticipantsBar (state)
      setShowParticipantsBar(msg?.show);
      // Swallow msg (don't forward to call machine)
    },
    [setShowParticipantsBar]
  );

  const {
    handleSetCustomIntegrationsMsg,
    handleStartCustomIntegrationsMsg,
    handleStopCustomIntegrationsMsg,
  } = useIframeCustomIntegrations();

  const handleUpdateCustomTrayButtonsMsg = useCallback(
    (msg: any) => {
      setCustomTrayButtons(msg?.btns);
    },
    [setCustomTrayButtons]
  );

  const isOwner = useParticipantProperty(useLocalSessionId(), 'owner');
  const availableSidebarViews = useMemo(() => {
    const availableViews: SidebarView[] = [
      null,
      ...sidebarIntegrations.map((i) => i.id),
    ];
    if (enablePeopleUI) availableViews.push('people');
    if (enableChat) availableViews.push('chat');
    if (enableNetworkUI) availableViews.push('network');
    if (enableBreakoutRooms && isOwner) availableViews.push('breakout');
    return availableViews;
  }, [
    enableBreakoutRooms,
    enableChat,
    enableNetworkUI,
    enablePeopleUI,
    isOwner,
    sidebarIntegrations,
  ]);

  const handleGetSidebarViewMsg = useCallback(
    (msg: any) => {
      messageChannel.sendMessageToDriver({
        ...msg,
        action: 'get-sidebar-view',
        view: sidebarView,
      });
    },
    [messageChannel, sidebarView]
  );

  const handleSetSidebarViewMsg = useCallback(
    (msg: any) => {
      const sidebarView: SidebarView = msg?.view;
      if (availableSidebarViews.includes(sidebarView)) {
        setSidebarView(sidebarView);
      } else {
        console.warn(`No sidebar view "${sidebarView}" available. Ignoring.`);
      }
    },
    [availableSidebarViews, setSidebarView]
  );

  const handleSetThemeMsg = useCallback(
    (msg: any) => {
      const theme: DailyThemeConfig = msg?.theme;
      if (!theme) return;
      const [light, dark] = convertDailyThemeColors(theme);
      setLightColors(light);
      setDarkColors(dark);
    },
    [setDarkColors, setLightColors]
  );

  const handleDriverShowNames = useCallback(
    (msg: any) => {
      // Map showNamesMode to showNames
      setShowNames(msg?.mode !== 'never');
      // Swallow msg (don't forward to call machine)
    },
    [setShowNames]
  );

  const handleDriverEnterFullscreen = useCallback(
    (_msg: any) => {
      // Map fullscreen to isFullscreen
      setIsFullscreen(true);
      // Swallow msg (don't forward to call machine)
    },
    [setIsFullscreen]
  );

  const handleDriverExitFullscreen = useCallback(
    (_msg: any) => {
      // Map exited-fullscreen to isFullscreen
      setIsFullscreen(false);
      // Swallow msg (don't forward to call machine)
    },
    [setIsFullscreen]
  );

  const handleDriverSetPlayNewParticipantSound = useCallback(
    (msg: any) => {
      setEnableJoinSound(msg.arg);
    },
    [setEnableJoinSound]
  );

  /**
   * Map preferredViewMode to activeSpeakerMode (used by API).
   */
  useEffect(() => {
    setActiveSpeakerMode(preferredViewMode === 'speaker');
  }, [preferredViewMode]);

  /**
   * Fire show-local-video-changed event when showLocalVideo changes.
   */
  useEffect(() => {
    messageChannel?.sendMessageToDriver({
      action: 'show-local-video-changed',
      show: showLocalVideo,
    });
  }, [messageChannel, showLocalVideo]);

  /**
   * Fire set-active-speaker-mode event when activeSpeakerMode changes.
   */
  useEffect(() => {
    messageChannel?.sendMessageToDriver({
      action: 'active-speaker-mode-change',
      enabled: activeSpeakerMode,
    });
  }, [activeSpeakerMode, messageChannel]);

  /**
   * Fire sidebar-view-changed event when sidebarView changes.
   */
  useEffect(() => {
    messageChannel?.sendMessageToDriver({
      action: 'sidebar-view-changed',
      view: sidebarView,
    });
  }, [sidebarView, messageChannel]);

  return {
    handleDriverEnterFullscreen,
    handleDriverExitFullscreen,
    handleDriverSetActiveSpeakerModeMsg,
    handleDriverSetPlayNewParticipantSound,
    handleDriverShowLocalVideoMsg,
    handleDriverShowNames,
    handleDriverShowParticipantsBarMsg,
    handleGetSidebarViewMsg,
    handleSetCustomIntegrationsMsg,
    handleSetSidebarViewMsg,
    handleSetThemeMsg,
    handleStartCustomIntegrationsMsg,
    handleStopCustomIntegrationsMsg,
    handleUpdateCustomTrayButtonsMsg,
  };
};
