import { DailyAdvancedConfig, DailyThemeConfig } from '@daily-co/daily-js';
import { useCallback, useEffect, useState } from 'react';

import {
  convertDailyThemeColors,
  defaultDarkColors,
  defaultLightColors,
} from '/lib/theme';

export const useIframeLauncher = () => {
  const [launchConfig, setLaunchConfig] = useState<{
    micAudioMode?: DailyAdvancedConfig['micAudioMode'];
    theme?: DailyThemeConfig;
  }>(null);
  const [lightColors, setLightColors] = useState(defaultLightColors);
  const [darkColors, setDarkColors] = useState(defaultDarkColors);

  const handleIframeDriverLaunchConfigMessage = useCallback(
    (ev: MessageEvent) => {
      if (ev.data.action !== 'iframe-launch-config') return;

      // Set theme provided in the launch config.
      const [light, dark] = convertDailyThemeColors(ev.data.theme);
      setLightColors(light);
      setDarkColors(dark);

      // Store launch config
      setLaunchConfig({
        micAudioMode: ev.data?.dailyConfig?.micAudioMode,
        theme: ev.data.theme as DailyThemeConfig,
      });

      window.removeEventListener(
        'message',
        handleIframeDriverLaunchConfigMessage
      );
    },
    []
  );

  // Tell the iframe driver that we're ready to receive our launch
  // configuration, which is needed before the app is rendered.
  // If we're not in an iframe, use a default instead (currently empty).
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const runsInFrame = params.has('emb');
    let driverSupportsLaunchConfig = false;
    let clientIdKey = 'callFrameId';
    if (params.has('dailyJsVersion')) {
      const versionParts = params.get('dailyJsVersion').split('.');
      const majorVersion = parseInt(versionParts[0], 10);
      const minorVersion = parseInt(versionParts[1], 10);
      if (majorVersion > 0 || minorVersion >= 16) {
        driverSupportsLaunchConfig = true;
      }
      if (majorVersion > 0 || minorVersion > 66) {
        clientIdKey = 'callClientId';
      }
    }
    if (runsInFrame && driverSupportsLaunchConfig) {
      window.addEventListener('message', handleIframeDriverLaunchConfigMessage);
      // Note: here we assume a valid embHref (else something's seriously wrong)
      const embeddingPageURL = new URL(params.get('embHref'));
      const targetOrigin =
        embeddingPageURL.protocol === 'file:' ? '*' : embeddingPageURL.origin;
      window.parent.postMessage(
        {
          action: 'iframe-ready-for-launch-config',
          [clientIdKey]: params.get('emb'),
          from: 'embedded',
          what: 'iframe-call-message',
        },
        targetOrigin
      );
    } else {
      setLaunchConfig({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    launchConfig,
    darkColors,
    lightColors,
  };
};
