import { DailyCallOptions } from '@daily-co/daily-js';
import { useCallback } from 'react';

import { useCallState } from '/contexts/CallProvider';
import IframeDriverMessageChannel from '/contexts/IframeDriverMessageChannel';
import { useCustomIntegrations } from '/hooks/useCustomIntegrations';
import { cloneParticipantsAndMungeForIframeProvider } from '/lib/participants';
import {
  useCustomTrayButtons,
  useMaxGridTilesPerPage,
  useMinGridTilesPerPage,
  useShowFullscreenButton,
  useShowLeaveButton,
  useShowLocalVideo,
  useShowParticipantsBar,
  useShowUserNameChangeUI,
} from '/lib/state/custom';
import { usePreferredViewMode } from '/lib/state/layout';
import { CUSTOMER_USER_DATA_KEY } from '/lib/userData';

export const useIframeMeetingLifecycleHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const { initializeCallArgs } = useCallState();
  const [, setShowFullscreenButton] = useShowFullscreenButton();
  const [, setShowLeaveButton] = useShowLeaveButton();
  const [, setShowLocalVideo] = useShowLocalVideo();
  const [, setMaxGridTilesPerPage] = useMaxGridTilesPerPage();
  const [, setMinGridTilesPerPage] = useMinGridTilesPerPage();
  const [, setPreferredViewMode] = usePreferredViewMode();
  const [, setCustomTrayButtons] = useCustomTrayButtons();
  const [, setShowUserNameChangeUI] = useShowUserNameChangeUI();
  const [, setShowParticipantsBar] = useShowParticipantsBar();
  const { setCustomIntegrations } = useCustomIntegrations();

  const handleDriverJoinMeetingMsg = useCallback(
    (msg: any) => {
      if (!msg.properties) return;

      const callArgs: DailyCallOptions = { ...msg.properties };

      // It is okay to overwrite the settings in properties with the settings in the
      // preload cache because:
      // 1. If a new settings is provided in the join() call, the settings in preload cache and
      //    properties will be the same. Hence, this will essentially be a no-op.
      // 2. If no new settings are not provided in the join() call, the settings in preload cache
      //    will be the most upto date setting.
      if (
        msg?.preloadCache?.inputSettings &&
        // (empty object means uninitialized; would fail validation if used)
        Object.keys(msg.preloadCache.inputSettings).length
      ) {
        callArgs.inputSettings = msg.preloadCache.inputSettings;
      }
      // != is on purpose because *DeviceId === false is valid
      if (msg?.preloadCache?.audioDeviceId != null) {
        callArgs.audioSource = msg.preloadCache.audioDeviceId;
      }
      if (msg?.preloadCache?.videoDeviceId != null) {
        callArgs.videoSource = msg.preloadCache.videoDeviceId;
      }

      if (callArgs.subscribeToTracksAutomatically !== undefined) {
        console.warn(
          'Prebuilt UI does not support subscribeToTracksAutomatically option; ignoring'
        );
        delete callArgs.subscribeToTracksAutomatically;
      }
      if (callArgs.layoutConfig) {
        if (callArgs.layoutConfig.grid) {
          if (Number.isInteger(callArgs.layoutConfig.grid.maxTilesPerPage)) {
            setMaxGridTilesPerPage(callArgs.layoutConfig.grid.maxTilesPerPage);
            delete callArgs.layoutConfig.grid.maxTilesPerPage;
          }
          if (Number.isInteger(callArgs.layoutConfig.grid.minTilesPerPage)) {
            setMinGridTilesPerPage(callArgs.layoutConfig.grid.minTilesPerPage);
            delete callArgs.layoutConfig.grid.minTilesPerPage;
          }
        }
      }
      if (callArgs.activeSpeakerMode !== undefined) {
        setPreferredViewMode(callArgs.activeSpeakerMode ? 'speaker' : 'grid');
        delete callArgs.activeSpeakerMode;
      }
      if (callArgs.customIntegrations !== undefined) {
        setCustomIntegrations(callArgs.customIntegrations);
        delete callArgs.customIntegrations;
      }
      if (callArgs.customTrayButtons !== undefined) {
        setCustomTrayButtons(callArgs.customTrayButtons);
        delete callArgs.customTrayButtons;
      }
      if (callArgs.showParticipantsBar !== undefined) {
        setShowParticipantsBar(callArgs.showParticipantsBar);
        delete callArgs.showParticipantsBar;
      }
      if (callArgs.showLocalVideo !== undefined) {
        setShowLocalVideo(callArgs.showLocalVideo);
        delete callArgs.showLocalVideo;
      }
      if (callArgs.showLeaveButton !== undefined) {
        setShowLeaveButton(callArgs.showLeaveButton);
        delete callArgs.showLeaveButton;
      }
      if (callArgs.showFullscreenButton !== undefined) {
        setShowFullscreenButton(callArgs.showFullscreenButton);
        delete callArgs.showFullscreenButton;
      }
      if (callArgs.userData !== undefined) {
        callArgs.userData = { [CUSTOMER_USER_DATA_KEY]: callArgs.userData };
      }
      if (callArgs.showUserNameChangeUI !== undefined) {
        setShowUserNameChangeUI(callArgs.showUserNameChangeUI);
        delete callArgs.showUserNameChangeUI;
      }
      initializeCallArgs(callArgs);
      // Swallow msg (don't forward to call machine): prebuilt UI knows to invoke
      // join() when appropriate. This avoids a multiple-join bug, and accounts
      // for actual join() occurring later in the prebuilt UI flow than when
      // the driver invokes it (i.e. when enable_prejoin_ui = true).
    },
    [
      initializeCallArgs,
      setCustomIntegrations,
      setCustomTrayButtons,
      setMaxGridTilesPerPage,
      setMinGridTilesPerPage,
      setPreferredViewMode,
      setShowFullscreenButton,
      setShowLeaveButton,
      setShowLocalVideo,
      setShowParticipantsBar,
      setShowUserNameChangeUI,
    ]
  );

  const handleCallMachineJoinedMeetingMsg = useCallback(
    (msg: any) => {
      // Remove tracks, which can't be serialized and sent through the
      // message channel.
      const newMsg = { ...msg };
      if (msg.participants) {
        newMsg.participants = cloneParticipantsAndMungeForIframeProvider(
          newMsg.participants
        );
      }
      messageChannel?.forwardPackagedMessageToDriver(newMsg);
    },
    [messageChannel]
  );

  return {
    handleDriverJoinMeetingMsg,
    handleCallMachineJoinedMeetingMsg,
  };
};
