/**
 * A message channel to and from a driver controlling the call UI via an iframe
 * (e.g. daily-js).
 * Based on WebMessageChannel in pluot-core.
 */
export default class IframeDriverMessageChannel {
  // The callClientId of the outside daily instance (driver)
  private _frameId: string;
  private _clientIdKey: string;
  private _embeddingPageURL: URL;

  private _wrappedListeners: { [listenerId: number]: (msg: any) => void } = {};

  private _incrementingListenerId: number = 0;

  constructor(frameId: string, embeddingPageURL: URL) {
    this._frameId = frameId;
    this._embeddingPageURL = embeddingPageURL;
    const params = new URLSearchParams(window.location.search);
    // parent clients using daily-js < 67 will include and expect the
    // callClientId to be keyed as callFrameId.
    this._clientIdKey = 'callFrameId';
    if (params.has('dailyJsVersion')) {
      const versionParts = params.get('dailyJsVersion').split('.');
      const majorVersion = parseInt(versionParts[0], 10);
      const minorVersion = parseInt(versionParts[1], 10);
      if (majorVersion > 0 || minorVersion > 66) {
        this._clientIdKey = 'callClientId';
      }
    }
  }

  addListenerForMessagesFromDriver(listener: (msg: any) => void): number {
    const wrappedListener = (evt: any) => {
      const isIframeCallMessage = evt?.data?.what === 'iframe-call-message';
      const hasAction = evt?.data?.action;
      const isFromModule = !evt?.data?.from || evt.data.from === 'module';
      // this listens to ALL messages which means it gets both messages from the
      // outter frame as well as our own internal calls. This handler only wants
      // to deal with those coming from the outter frame/driver. Since internal
      // calls will all key the id using callClientId but the outter frame could
      // use either callClientId or callFrameId, we look for either
      let callClientId = evt?.data?.callClientId;
      if (!callClientId) {
        callClientId = evt?.data?.callFrameId;
        if (callClientId) {
          // if an older daily-js sent the message (< 0.67.0), update the key
          evt.data.callClientId = callClientId;
          delete evt.data.callFrameId;
        }
      }
      const isTargetFrame =
        callClientId && this._frameId ? callClientId === this._frameId : true;
      if (
        !(isIframeCallMessage && hasAction && isFromModule && isTargetFrame)
      ) {
        return;
      }
      const msg = evt.data;
      listener(msg);
    };
    this._wrappedListeners[this._incrementingListenerId] = wrappedListener;
    window.addEventListener('message', wrappedListener);
    return this._incrementingListenerId++;
  }

  removeListenerForMessagesFromDriver(listenerId: number): void {
    const wrappedListener = this._wrappedListeners[listenerId];
    if (wrappedListener) {
      window.removeEventListener('message', wrappedListener);
      delete this._wrappedListeners[listenerId];
    }
  }

  sendMessageToDriver(message: any) {
    message.what = 'iframe-call-message';
    message[this._clientIdKey] = this._frameId;
    message.from = 'embedded';
    window.parent?.postMessage(
      message,
      this._targetOriginFromEmbeddingPageURL()
    );
  }

  forwardPackagedMessageToDriver(msg: any): void {
    const newMsg = { ...msg };
    newMsg[this._clientIdKey] = this._frameId;
    window.parent?.postMessage(
      newMsg,
      this._targetOriginFromEmbeddingPageURL()
    );
  }

  // Converts this embedding page's URL into something that can be used as a
  // target origin for window.postMessage() calls.
  _targetOriginFromEmbeddingPageURL() {
    // According to MDN: 'posting a message to a page at a `file:` URL currently requires that the targetOrigin argument be "*"'
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    return this._embeddingPageURL.protocol === 'file:'
      ? '*'
      : this._embeddingPageURL.origin;
  }
}
