import { DailyVideoSendSettings } from '@daily-co/daily-js';

/**
 * Fallback value, for when sendSettings are not available or not set
 */
export const P2P_LOW_KBS_FALLBACK = 300;
/**
 * Fallback value, for when sendSettings are not available or not set
 */
export const P2P_HIGH_KBS_FALLBACK = 980;

export const getMaxBitrate = (
  sendSettings: DailyVideoSendSettings,
  layer: keyof DailyVideoSendSettings['encodings']
) => {
  if (typeof sendSettings !== 'object') return 0;
  return sendSettings?.encodings?.[layer]?.maxBitrate ?? 0;
};

/**
 * Calculates and returns low kbs value for P2P mode.
 */
export const getP2PLowKbs = (sendSettings: DailyVideoSendSettings) => {
  if (typeof sendSettings !== 'object' || !sendSettings)
    return P2P_LOW_KBS_FALLBACK;

  const lowBitrate = getMaxBitrate(sendSettings, 'low');
  const mediumBitrate = getMaxBitrate(sendSettings, 'medium');

  /**
   * Amount of configured sendSettings layers
   */
  const layersCount = Object.keys(sendSettings?.encodings ?? {}).length;

  switch (layersCount) {
    case 3: // low, medium, high -> drop high
      return Math.floor((lowBitrate + mediumBitrate) / 1000);
    case 2: // low, medium -> drop medium
    case 1: // low -> determine lower value
      return Math.min(P2P_LOW_KBS_FALLBACK, Math.floor(lowBitrate / 1000));
    case 0:
      return P2P_LOW_KBS_FALLBACK;
  }
};

/**
 * Calculates and returns high kbs value for P2P mode.
 */
export const getP2PHighKbs = (sendSettings: DailyVideoSendSettings) => {
  if (typeof sendSettings !== 'object' || !sendSettings)
    return P2P_HIGH_KBS_FALLBACK;

  const lowBitrate = getMaxBitrate(sendSettings, 'low');
  const mediumBitrate = getMaxBitrate(sendSettings, 'medium');
  const highBitrate = getMaxBitrate(sendSettings, 'high');

  return Math.max(
    Math.floor((lowBitrate + mediumBitrate + highBitrate) / 1000),
    P2P_HIGH_KBS_FALLBACK
  );
};

/**
 * Calculates and returns the highest possible encoding layer.
 */
export const getHighLayer = (sendSettings: DailyVideoSendSettings) => {
  if (
    typeof sendSettings !== 'object' ||
    !sendSettings ||
    !sendSettings.encodings
  )
    return 'high';
  if ('high' in sendSettings.encodings) return 'high';
  if ('medium' in sendSettings.encodings) return 'medium';
  return 'low';
};

/**
 * Calculates and returns the layer below highest encoding. For 3 layers this is "medium", for 1-2 layers it's "low".
 */
export const getLowLayer = (sendSettings: DailyVideoSendSettings) => {
  if (
    typeof sendSettings !== 'object' ||
    !sendSettings ||
    !sendSettings.encodings
  )
    return 'medium';
  if ('high' in sendSettings.encodings) return 'medium';
  if ('medium' in sendSettings.encodings) return 'low';
  return 'low';
};
