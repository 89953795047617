import classnames from 'classnames';
import React, { forwardRef } from 'react';

import { scrollToElement } from '../../lib/dom';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  underline?: boolean;
}

export const Anchor = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, href, onClick, underline, ...props }, ref) => {
    const isExternalHref = href && href.match(/^(https?:)?\/\//);
    const isHashHref = href?.match(/^#/);

    const targetProps = isExternalHref
      ? {
          rel: 'noopener',
          target: '_blank',
        }
      : {};

    const handleClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
      if (isHashHref) {
        ev.preventDefault();
        const targetEl = document.getElementById(href.slice(1));
        if (targetEl) {
          scrollToElement(targetEl);
        }
      }
      onClick?.(ev);
    };

    return (
      <a
        ref={ref}
        className={classnames(className, { underline })}
        href={href}
        onClick={handleClick}
        {...targetProps}
        {...props}
      >
        {children}
        <style jsx>{`
          a {
            color: inherit;
            cursor: pointer;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            position: relative;
            text-decoration: none;
          }
          a.underline {
            text-decoration: underline;
          }
          a:focus-visible {
            border-radius: 2px;
            outline: 2px solid var(--focus-shadow);
          }
        `}</style>
      </a>
    );
  }
);
