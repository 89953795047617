import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface Props extends React.HTMLAttributes<HTMLElement> {
  el?: string;
}

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const Portal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  el = 'div',
  ...rest
}) => {
  const portal = useRef(canUseDOM ? document.createElement(el) : null);

  useEffect(() => {
    if (!portal.current) return;

    // assigning all the HTMLElement properties to the element
    const portalEl = Object.assign(portal.current, rest);

    document.body.appendChild(portalEl);
    return () => {
      document.body.removeChild(portalEl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return canUseDOM ? ReactDOM.createPortal(children, portal.current) : null;
};
