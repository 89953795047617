import { atom, useAtom } from 'jotai';

/**
 * Modal visibility states.
 * There are a couple of single-purpose modals whose visibility is controlled
 * via shared UI state.
 */
export type SettingsView =
  | 'devices'
  | 'quality'
  | 'user-preferences'
  | 'effects'
  | 'version';
const settingsViewAtom = atom<SettingsView | null>(null as SettingsView);
/**
 * Used to control visibility and tabs for SettingsModal.
 */
export const useSettingsView = () => useAtom(settingsViewAtom);

const autoplayFailedModalAtom = atom(false);
/**
 * Used to control visibility of AutoPlayFailedModal.
 */
export const useAutoplayFailedModal = () => useAtom(autoplayFailedModalAtom);

const deviceInUseModalAtom = atom(false);
/**
 * Used to control visibility of DeviceInUseModal.
 */
export const useDeviceInUseModal = () => useAtom(deviceInUseModalAtom);

const deviceNotFoundModalAtom = atom(false);
/**
 * Used to control visibility of DeviceNotFoundModal.
 */
export const useDeviceNotFoundModal = () => useAtom(deviceNotFoundModalAtom);

const nameModalAtom = atom(false);
/**
 * Used to control visibility of NameModal.
 */
export const useNameModal = () => useAtom(nameModalAtom);

const startScreenshareModalAtom = atom(false);
/**
 * Used to control visibility of StartScreenshareModal.
 */
export const useStartScreenshareModal = () =>
  useAtom(startScreenshareModalAtom);

type ScreenshareError = 'blocked-by-os' | 'blocked-by-browser';

const screenshareErrorModalAtom = atom<ScreenshareError | null>(
  null as ScreenshareError
);
/**
 * Used to control visibility of ScreenshareErrorModal.
 */
export const useScreenshareErrorModal = () =>
  useAtom(screenshareErrorModalAtom);

const unblockPermissionsModalAtom = atom(false);
/**
 * Used to control visibility of UnblockPermissionsModal.
 */
export const useUnblockPermissionsModal = () =>
  useAtom(unblockPermissionsModalAtom);

const muteAllModalAtom = atom(false);
/**
 * Used to control visibility of MuteAllModal.
 */
export const useMuteAllModal = () => useAtom(muteAllModalAtom);

const appsModalAtom = atom(false);
/**
 * Used to control visibility of AppsModal.
 */
export const useAppsModal = () => useAtom(appsModalAtom);
