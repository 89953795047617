import React, { createContext, useCallback, useState } from 'react';

import { Snackbar } from '../../components/Snackbar';

interface Message
  extends Omit<React.ComponentProps<typeof Snackbar>, 'children' | 'id'> {
  content: React.ReactNode;
}

interface ActiveMessage {
  id: number;
  message: Message;
  icon?: JSX.Element;
}

export interface SnackbarProviderValue {
  addMessage(m: Message): void;
  messages: ActiveMessage[];
  handleHide(n: Number): void;
}

export const SnackbarContext = createContext<SnackbarProviderValue>({
  addMessage: () => {},
  messages: [],
  handleHide: () => {},
});

let counter = 0;

export const SnackbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [messages, setMessages] = useState<ActiveMessage[]>([]);

  const addMessage = useCallback((m: Message) => {
    if (!m.type) m.type = 'success';
    setMessages((msgs) => [
      {
        id: ++counter,
        message: m,
      },
      ...msgs,
    ]);
  }, []);

  const handleHide = (hideId: number) => {
    setMessages((msgs) => msgs.filter(({ id }) => id !== hideId));
  };

  return (
    <SnackbarContext.Provider
      value={{
        addMessage,
        messages,
        handleHide,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
