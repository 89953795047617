import { atom, useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';

const prebuiltDebuggerEnabledAtom = atom(false);

export const useDebugging = () => {
  const prebuiltDebuggerEnabled = useAtomValue(prebuiltDebuggerEnabledAtom);
  const enablePrebuiltDebugger = useAtomCallback(
    useCallback((_get, set) => {
      set(prebuiltDebuggerEnabledAtom, true);
    }, [])
  );
  const disablePrebuiltDebugger = useAtomCallback(
    useCallback((_get, set) => {
      set(prebuiltDebuggerEnabledAtom, false);
    }, [])
  );

  return {
    prebuiltDebuggerEnabled,
    enablePrebuiltDebugger,
    disablePrebuiltDebugger,
  };
};
