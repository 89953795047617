import { useDaily } from '@daily-co/daily-react';
import { useCallback } from 'react';

import IframeDriverMessageChannel from '/contexts/IframeDriverMessageChannel';
import { cloneParticipantAndMungeForIframeProvider } from '/lib/participants';
import {
  CUSTOMER_USER_DATA_KEY,
  getPrebuiltUserData,
  PREBUILT_USER_DATA_KEY,
} from '/lib/userData';

export const useIframeParticipantsHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const daily = useDaily();

  const handleDriverUpdateParticipantMsg = useCallback(
    (msg: any) => {
      if (
        msg.properties &&
        msg.properties['setSubscribedTracks'] !== undefined
      ) {
        console.warn(
          'Prebuilt UI does not support setSubscribedTracks in updateParticipant(); ignoring'
        );
        delete msg.properties['setSubscribedTracks'];
      }
      // @ts-ignore
      daily?.forwardPackagedMessageToCallMachine(msg);
    },
    [daily]
  );

  const handleDriverUpdateParticipantsMsg = useCallback(
    (msg: any) => {
      if (msg.participants) {
        for (const participantUpdates of Object.values(msg.participants)) {
          if (
            participantUpdates &&
            participantUpdates['setSubscribedTracks'] !== undefined
          ) {
            console.warn(
              'Prebuilt UI does not support setSubscribedTracks in updateParticipants(); ignoring'
            );
            delete participantUpdates['setSubscribedTracks'];
          }
        }
      }
      // @ts-ignore
      daily?.forwardPackagedMessageToCallMachine(msg);
    },
    [daily]
  );

  const handleDriverSetUserDataMsg = useCallback(
    (msg: any) => {
      const prebuiltUserData = getPrebuiltUserData(daily);
      let userData = { [CUSTOMER_USER_DATA_KEY]: msg.userData };

      // if both our data and iframe data is undefined, then setUserData to
      // undefined to take advantage of a slimmed down presence payload
      if (msg.userData === undefined && prebuiltUserData === undefined) {
        userData = undefined;
      } else if (prebuiltUserData !== undefined) {
        userData[PREBUILT_USER_DATA_KEY] = prebuiltUserData;
      }

      msg.userData = userData;
      // @ts-ignore
      daily?.forwardPackagedMessageToCallMachine(msg);
    },
    [daily]
  );

  const handleCallMachineParticipantMsg = useCallback(
    (msg: any) => {
      // Remove tracks, which can't be serialized and sent through the
      // message channel and clean up userData to only include iframe-set
      // data.
      const newMsg = { ...msg };
      if (msg.participant) {
        newMsg.participant = cloneParticipantAndMungeForIframeProvider(
          msg.participant
        );
      }
      messageChannel?.forwardPackagedMessageToDriver(newMsg);
    },
    [messageChannel]
  );

  return {
    handleDriverUpdateParticipantMsg,
    handleDriverUpdateParticipantsMsg,
    handleDriverSetUserDataMsg,
    handleCallMachineParticipantMsg,
  };
};
