import { DailyCustomIntegrations } from '@daily-co/daily-js';
import { useCallback } from 'react';

import { useCustomIntegrations } from '/hooks/useCustomIntegrations';

export const useIframeCustomIntegrations = () => {
  const { setCustomIntegrations, startIntegration, stopIntegration } =
    useCustomIntegrations();

  const handleStartCustomIntegrationsMsg = useCallback(
    (msg: any) => {
      msg.ids.forEach(startIntegration);
    },
    [startIntegration]
  );
  const handleStopCustomIntegrationsMsg = useCallback(
    (msg: any) => {
      msg.ids.forEach(stopIntegration);
    },
    [stopIntegration]
  );

  /**
   * When the client calls `setCustomIntegrations` this function is executed.
   */
  const handleSetCustomIntegrationsMsg = useCallback(
    (msg: any) => {
      const newIntegrations: DailyCustomIntegrations = msg?.integrations ?? {};
      setCustomIntegrations(newIntegrations);
    },
    [setCustomIntegrations]
  );

  return {
    handleSetCustomIntegrationsMsg,
    handleStartCustomIntegrationsMsg,
    handleStopCustomIntegrationsMsg,
  };
};
