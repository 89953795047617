import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import {
  DailyParticipant,
  DailyParticipantUpdateOptions,
  DailyTrackSubscriptionState,
} from '@daily-co/daily-js';
import {
  useActiveSpeakerId,
  useDaily,
  useLocalSessionId,
  useNetwork,
  useParticipantIds,
  useThrottledDailyEvent,
} from '@daily-co/daily-react';
import deepEqual from 'fast-deep-equal';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '/hooks/useCallConfig';
import { isParticipantTrackOff } from '/lib/participants';
import { getQueryParam } from '/lib/query';
import { useViewMode } from '/lib/state/layout';

import {
  useFilteredParticipantIds,
  useOrderedParticipantIds,
} from './ParticipantsProvider';

/**
 * Maximum amount of concurrently subscribed or staged most recent speakers.
 */
export const MAX_RECENT_SPEAKER_COUNT = 16;
/**
 * Threshold up to which all cams will be subscribed to or staged.
 * If the remote participant count passes this threshold,
 * cam subscriptions are defined by UI view modes.
 */
const SUBSCRIBE_OR_STAGE_ALL_VIDEO_THRESHOLD = 9;

interface ContextValue {
  subscribeToCam(id: string): void;
  updateCamSubscriptions(subscribedIds: string[], stagedIds?: string[]): void;
}

const TracksContext = createContext<ContextValue>(null);

const maxCamSubscriptionsAtom = atom<number | null>(null as number);
export const useMaxCamSubscriptions = () =>
  useAtomValue(maxCamSubscriptionsAtom);

const TracksEffects: React.FC = memo(() => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const daily = useDaily();
  const { optimizeLargeCalls } = useCallConfig();
  const { topology } = useNetwork();
  const setMaxCamSubscriptions = useSetAtom(maxCamSubscriptionsAtom);
  const { addMessage } = useSnackbar();
  const filteredParticipantIds = useFilteredParticipantIds();
  const orderedParticipantIds = useOrderedParticipantIds();
  const recentSpeakerIds = useRecentSpeakerIds();
  const localSessionId = useLocalSessionId();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /**
   * Automatically update audio, screenAudio and screenVideo subscriptions.
   */
  useEffect(() => {
    if (!daily || daily.isDestroyed() || !topology) return;
    /**
     * A little throttling as we want daily-js to have some room to breathe ☺️
     */
    const timeout = setTimeout(() => {
      const participants = daily.participants();
      const updates = remoteParticipantIds.reduce<
        Record<string, DailyParticipantUpdateOptions>
      >((u, id) => {
        // Ignore undefined, local or screenshare participant ids
        if (!id || id === localSessionId) return u;
        const isSpeaker =
          recentSpeakerIds.includes(id) ||
          !isParticipantTrackOff(participants[id], 'audio');
        const hasSubscribed = (type: keyof DailyParticipant['tracks']) =>
          participants[id]?.tracks?.[type]?.subscribed;
        const isRelevant = orderedParticipantIds.includes(id);
        const shouldSubscribe = (type: keyof DailyParticipant['tracks']) => {
          // If we're not in SFU, no track subscriptions!
          if (topology !== 'sfu') return true;
          // When optimized for large calls (and in SFU) only subscribe to speakers.
          if (
            optimizeLargeCalls &&
            type === 'audio' &&
            filteredParticipantIds === null // Can't determine active speaker, when only subscribed to a subgroup
          )
            return isSpeaker;
          // Otherwise only subscribe to relevant participants.
          return isRelevant;
        };

        /**
         * Updates the subscription update object for the current participant and given track type.
         * @param type The track type to update the subscription for.
         */
        const setSubscribedTrack = (type: keyof DailyParticipant['tracks']) => {
          const _hasSubscribed = hasSubscribed(type);
          const _shouldSubscribe = shouldSubscribe(type);
          if (
            (!_hasSubscribed && _shouldSubscribe) ||
            (_hasSubscribed && !_shouldSubscribe)
          ) {
            if (typeof u[id] !== 'object') {
              u[id] = {
                setSubscribedTracks: {},
              };
            }
            u[id].setSubscribedTracks[type] = _shouldSubscribe;
          }
        };

        /**
         * In optimized calls:
         * - subscribe to speakers we're not subscribed to, yet
         * - unsubscribe from non-speakers we're subscribed to
         * In non-optimized calls:
         * - subscribe to all who we're not to subscribed to, yet
         */
        setSubscribedTrack('audio');

        /**
         * Update screen subscriptions based on orderedParticipantIds.
         */
        setSubscribedTrack('screenAudio');
        setSubscribedTrack('screenVideo');

        return u;
      }, {});
      if (Object.keys(updates).length === 0 || daily.isDestroyed()) return;
      daily.updateParticipants(updates);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    daily,
    filteredParticipantIds,
    localSessionId,
    optimizeLargeCalls,
    orderedParticipantIds,
    recentSpeakerIds,
    remoteParticipantIds,
    topology,
  ]);

  /**
   * Notify user when pushed out of recent speakers queue.
   */
  const showMutedMessage = useRef(false);
  useEffect(() => {
    if (!daily || !optimizeLargeCalls) return;

    if (recentSpeakerIds.some((id) => id === localSessionId)) {
      showMutedMessage.current = true;
      return;
    }
    if (
      showMutedMessage.current &&
      !isParticipantTrackOff(daily.participants()?.local, 'audio')
    ) {
      daily.setLocalAudio(false);
      addMessage({
        content: t('notification.micMutedAutomatically'),
      });
      showMutedMessage.current = false;
    }
  }, [
    addMessage,
    daily,
    localSessionId,
    optimizeLargeCalls,
    recentSpeakerIds,
    t,
  ]);

  useThrottledDailyEvent(
    'participant-joined',
    useCallback(
      async (evts) => {
        const ids = evts.map((ev) => ev.participant.session_id);
        const topology = (await daily.getNetworkTopology())?.topology;
        const updates: Record<string, DailyParticipantUpdateOptions> =
          ids.reduce((o, id) => {
            if (topology === 'peer') {
              o[id] = { setSubscribedTracks: true };
            }
            return o;
          }, {});
        if (Object.keys(updates).length === 0) return;
        daily.updateParticipants(updates);
      },
      [daily]
    )
  );

  useEffect(() => {
    if (query.max_cam_subs) {
      const maxSubs = parseInt(getQueryParam('max_cam_subs', query), 10);
      if (maxSubs && !Number.isNaN(Math.abs(maxSubs))) {
        setMaxCamSubscriptions(maxSubs);
      }
    }
  }, [query, setMaxCamSubscriptions]);

  useEffect(() => {
    if (optimizeLargeCalls) {
      setMaxCamSubscriptions(36);
    }
  }, [optimizeLargeCalls, setMaxCamSubscriptions]);

  return null;
});
TracksEffects.displayName = 'TracksEffects';

export const TracksProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const daily = useDaily();
  const { topology } = useNetwork();
  const viewMode = useViewMode();

  const recentSpeakerIds = useRecentSpeakerIds();
  const localSessionId = useLocalSessionId();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  const subscribeToCam = useCallback(
    (id: string) => {
      /**
       * Ignore undefined, local or screenshare.
       */
      if (!id || id === localSessionId) return;
      daily.updateParticipant(id, {
        setSubscribedTracks: { video: true },
      });
    },
    [daily, localSessionId]
  );

  const lastSubscriptions = useRef<
    Record<string, DailyParticipantUpdateOptions>
  >({});
  /**
   * Updates cam subscriptions based on passed subscribedIds and stagedIds.
   * For ids not provided, cam tracks will be unsubscribed from.
   *
   * @param subscribedIds Participant ids whose cam tracks should be subscribed to.
   * @param stagedIds Participant ids whose cam tracks should be staged.
   */
  const updateCamSubscriptions = useCallback(
    (subscribedIds: string[], stagedIds: string[] = []) => {
      if (!daily) return;

      // If total number of remote participants is less than a threshold, simply
      // stage all remote cams that aren't already marked for subscription.
      // Otherwise, honor the provided stagedIds, with recent speakers appended
      // who aren't already marked for subscription.
      if (
        remoteParticipantIds.length <= SUBSCRIBE_OR_STAGE_ALL_VIDEO_THRESHOLD
      ) {
        stagedIds = remoteParticipantIds.filter(
          (id) => !subscribedIds.includes(id)
        );
      } else {
        if (viewMode !== 'grid') {
          stagedIds.push(
            ...recentSpeakerIds.filter((id) => !subscribedIds.includes(id))
          );
        }
      }

      // Assemble updates to get to desired cam subscriptions
      const updates = remoteParticipantIds.reduce<
        Record<string, DailyParticipantUpdateOptions>
      >((u, id) => {
        let desiredSubscription: DailyTrackSubscriptionState;
        const currentSubscription =
          daily.participants()?.[id]?.tracks?.video?.subscribed;

        // Ignore undefined, local or screenshare participant ids
        if (!id || id === localSessionId) return u;

        // Decide on desired cam subscription for this participant:
        // subscribed, staged, or unsubscribed
        if (subscribedIds.includes(id) || topology !== 'sfu') {
          desiredSubscription = true;
        } else if (stagedIds.includes(id)) {
          desiredSubscription = 'staged';
        } else {
          desiredSubscription = false;
        }

        // Skip if we already have the desired subscription to this
        // participant's cam
        if (desiredSubscription === currentSubscription) return u;

        u[id] = {
          setSubscribedTracks: {
            video: desiredSubscription,
          },
        };
        return u;
      }, {});

      if (Object.keys(updates).length === 0) return;
      // Ignore API call if updates were already applied or callObject is destroyed
      if (deepEqual(updates, lastSubscriptions.current) || daily.isDestroyed())
        return;
      lastSubscriptions.current = { ...updates };
      daily.updateParticipants(updates);
    },
    [
      daily,
      localSessionId,
      remoteParticipantIds,
      recentSpeakerIds,
      topology,
      viewMode,
    ]
  );

  return (
    <TracksContext.Provider
      value={{
        subscribeToCam,
        updateCamSubscriptions,
      }}
    >
      {children}
      <TracksEffects />
    </TracksContext.Provider>
  );
};

export const useTracks = () => useContext(TracksContext);

const recentSpeakersAtom = atom<string[]>([]);

/**
 * Returns the ${MAX_RECENT_SPEAKER_COUNT} most recent speakers,
 * ordered by their most recent audio activity.
 */
export const useRecentSpeakerIds = () => {
  const [recentSpeakers, setRecentSpeakers] = useAtom(recentSpeakersAtom);

  const activeSpeakerId = useActiveSpeakerId();

  useEffect(() => {
    setRecentSpeakers((prevSpeakers) => {
      return [
        activeSpeakerId,
        ...prevSpeakers.filter((id) => id !== activeSpeakerId),
      ]
        .filter(Boolean)
        .slice(0, MAX_RECENT_SPEAKER_COUNT);
    });
  }, [activeSpeakerId, setRecentSpeakers]);

  return useMemo(() => recentSpeakers, [recentSpeakers]);
};
