import React from 'react';
import { useTranslation } from 'react-i18next';

import { FinalMessage } from './App/FinalMessage';

/* Our custom NextJS error page, which should never be shown because it means
 * something is very wrong in our app 😅
 * For example: a component triggers an infinite render loop, or a call to a room
 * returns a 500 response from our servers. This page will _not_ override our custom
 * "room could not be found" error. */
export default function CustomAppError() {
  const { t } = useTranslation();

  return (
    <div className="error-page">
      <FinalMessage
        title={t('lobby.error.title')}
        desc={t('lobby.error.desc')}
        variant="error"
      />
      <style jsx>{`
        .error-page {
          height: 100vh;
          width: 100%;
          max-width: 65ch;
          margin: 0 auto;
        }
      `}</style>
    </div>
  );
}
