export type VideoQuality = 'auto' | 'high' | 'low' | 'bandwidth-saver';

export function getIntendedQuality({
  camMuteDisabled,
  isSharingScreen,
  networkThreshold,
  qualitySetting,
}): VideoQuality {
  // In very bad network conditions, we'll disregard the user's qualitySetting preferences.
  if (networkThreshold === 'very-low' || qualitySetting === 'bandwidth-saver') {
    return camMuteDisabled ? 'low' : 'bandwidth-saver';
  }
  // set quality to low or bandwidth saver when: the network conditions are bad, the user selected it, or when they are screen sharing.
  if (
    networkThreshold === 'low' ||
    qualitySetting === 'low' ||
    isSharingScreen
  ) {
    return 'low';
  }

  if (networkThreshold === 'good' && !isSharingScreen) {
    return qualitySetting;
  }
}
