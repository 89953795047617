import { Button } from '@daily/shared/components/Button';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  error?: Error;
  retry?(): void;
  style?: React.CSSProperties;
  timedOut?: boolean;
}

export const ChunkLoader: React.FC<Props> = ({
  className,
  error,
  retry,
  style = {},
  timedOut,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('loader', className)} style={style}>
      {error || timedOut ? (
        <Button variant="ghost" onClick={retry}>
          {t('general.retry')}
        </Button>
      ) : (
        <LoadingSpinner />
      )}
      <style jsx>{`
        .loader {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;
        }
      `}</style>
    </div>
  );
};
