import { useCallConfig } from '/hooks/useCallConfig';

interface Props {
  check(config: ReturnType<typeof useCallConfig>): boolean;
  Provider: React.ComponentType<React.PropsWithChildren>;
}
export const ConditionalProvider: React.FC<React.PropsWithChildren<Props>> = ({
  check,
  children,
  Provider,
}) => {
  const config = useCallConfig();
  if (check(config)) {
    return <Provider>{children}</Provider>;
  }
  return <>{children}</>;
};
