import { DailyEventObjectWaitingParticipant } from '@daily-co/daily-js';
import { useDaily, useWaitingParticipants } from '@daily-co/daily-react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useSoundLoader } from '/hooks/useSoundLoader';

export interface WaitingParticipant {
  id: string;
  joinDate: Date;
  name: string;
}

interface ContextValue {
  setShowModal(b: boolean): void;
  showModal: boolean;
}

const WaitingRoomContext = createContext<ContextValue>(null);

export const WaitingRoomProvider: React.FC<React.PropsWithChildren<unknown>> =
  ({ children }) => {
    const { knockSound } = useSoundLoader();
    const [showModal, setShowModal] = useState(false);

    const daily = useDaily();
    const handleWaitingParticipantAdded = useCallback(
      (ev: DailyEventObjectWaitingParticipant) => {
        const waiting = Object.entries(daily.waitingParticipants());
        /**
         * Only play knocking sound for first participant in waiting queue.
         */
        if (ev.action === 'waiting-participant-added' && waiting.length === 1) {
          knockSound.play();
        }
      },
      [daily, knockSound]
    );
    const { waitingParticipants } = useWaitingParticipants({
      onWaitingParticipantAdded: handleWaitingParticipantAdded,
    });

    useEffect(() => {
      if (waitingParticipants.length === 0) {
        setShowModal(false);
      }
    }, [waitingParticipants]);

    return (
      <WaitingRoomContext.Provider
        value={{
          setShowModal,
          showModal,
        }}
      >
        {children}
      </WaitingRoomContext.Provider>
    );
  };

export const useWaitingRoom = () => useContext(WaitingRoomContext);
