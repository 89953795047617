import classNames from 'classnames';
import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  id: string;
  muted?: boolean;
  transition?: boolean;
  size?: number;
}

export const CamIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  id,
  muted = false,
  transition = true,
  size = 24,
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <mask id={`camIconMask${id}`}>
        <rect x="0" y="0" width="24" height="24" fill="white" />
        <path
          className={classNames('strike', { muted, transition })}
          d="M2.87715 19L18.8772 3"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </mask>
      <g mask={muted ? `url(#camIconMask${id})` : undefined}>
        <path d="M5 7.5C3.89543 7.5 3 8.39543 3 9.5V14.5C3 15.6046 3.89543 16.5 5 16.5H13C14.1046 16.5 15 15.6046 15 14.5V9.5C15 8.39543 14.1046 7.5 13 7.5H5Z" />
        <path d="M16.5 10.9491C16.5 10.6634 16.6221 10.3914 16.8356 10.2017L19.3356 7.97943C19.9805 7.40618 21 7.86399 21 8.72684V15.2732C21 16.136 19.9805 16.5938 19.3356 16.0206L16.8356 13.7983C16.6221 13.6086 16.5 13.3366 16.5 13.0509V10.9491Z" />
      </g>
      <path
        className={classNames('strike', { muted, transition })}
        d="M4 20L20 4"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <style jsx>{`
        svg {
          fill: ${color};
          height: ${size}px;
          width: ${size}px;
          display: block;
        }
        .strike {
          transform-origin: center;
          transform: scale(0);
        }
        .strike.transition {
          transition: transform 240ms ease;
        }
        .strike.muted {
          transform: scale(1);
        }
      `}</style>
    </svg>
  );
};
