import { Anchor } from '@daily/shared/components/Anchor';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useResize } from '@daily/shared/hooks/useResize';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useStyleVariants } from '/hooks/useStyleVariants';

export const PoweredByDailyBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { colors } = useTheme();
  const { textVariant } = useStyleVariants();
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsVisible(true);
    return () => {
      document.body.style.removeProperty('--banner-height');
    };
  }, []);

  useResize(
    useCallback(() => {
      if (!bannerRef.current || !isVisible) return;
      document.body.style.setProperty(
        '--banner-height',
        `${bannerRef.current.clientHeight}px`
      );
    }, [isVisible])
  );

  return (
    <div ref={bannerRef}>
      <Text color="white" variant={textVariant}>
        Powered by{' '}
        <Anchor href="https://daily.co" underline>
          Daily
        </Anchor>
      </Text>
      <style jsx>{`
        div {
          align-items: center;
          background: ${colors.system.greyDark};
          display: flex;
          flex: none;
          justify-content: center;
          padding: 6px 8px 8px;
          width: 100%;
        }
      `}</style>
    </div>
  );
};
