import { atom, useAtom } from 'jotai';

import { VideoQuality } from '/lib/getIntendedQuality';

const videoQualityAtom = atom<VideoQuality>('auto');

export const useVideoQuality = () => {
  const [videoQuality, setVideoQuality] = useAtom(videoQualityAtom);

  return {
    videoQuality,
    setVideoQuality,
  };
};
