import i18n from 'i18next';
import backend from 'i18next-http-backend';
import getConfig from 'next/config';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line @dword-design/import-alias/prefer-alias
import enTranslations from '../public/locales/en/translation.json';

const { assetPrefix } = getConfig().publicRuntimeConfig;

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      addPath: null,
      crossDomain: true,
      loadPath: `${assetPrefix}/locales/{{lng}}/{{ns}}.json`,
    },
    supportedLngs: [
      'da',
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'it',
      'jp',
      'ka',
      'nl',
      'no',
      'pl',
      'pt',
      'pt-BR',
      'ru',
      'sv',
      'tr',
    ],
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    saveMissing: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

i18n.addResourceBundle('en', 'translation', enTranslations);

i18n.services.formatter.add('lowercase', (value: string) => {
  return value.toLowerCase();
});

export default i18n;
