import { DailyVideoSendSettings } from '@daily-co/daily-js';
import {
  useDaily,
  useMeetingState,
  useNetwork,
  useParticipantIds,
  useScreenShare,
  useSendSettings,
} from '@daily-co/daily-react';
import { useCallback, useEffect, useRef } from 'react';

import {
  getHighLayer,
  getLowLayer,
  getP2PHighKbs,
  getP2PLowKbs,
} from '/lib/bandwidth-controls';
import { getIntendedQuality, VideoQuality } from '/lib/getIntendedQuality';

import { useVideoQuality } from './useVideoQuality';

/**
 * Automatically updates bandwidth controls based on topology, network threshold and videoQuality.
 */
export const useBandwidthControls = () => {
  const { videoQuality } = useVideoQuality();
  const daily = useDaily();
  const meetingState = useMeetingState();
  const { threshold, topology } = useNetwork();
  const { isSharingScreen } = useScreenShare();
  const lastQualitySet = useRef<VideoQuality>(null);
  const lastSetKBS = useRef<number>(null);
  const lastSetMaxQuality = useRef<DailyVideoSendSettings['maxQuality']>(null);
  const { sendSettings, updateSendSettings } = useSendSettings();

  const remotePeers = useParticipantIds({ filter: 'remote' });

  /**
   * Query param based flag to turn off auto-cam-mute on very-low network.
   */
  const disableCameraNetworkQualityMute = useRef(false);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    disableCameraNetworkQualityMute.current = query.has(
      'disableCameraNetworkQualityMute'
    );
  }, []);

  const setQuality = useCallback(
    (q: VideoQuality) => {
      if (!daily || daily.isDestroyed()) return;
      if (['new', 'loading'].includes(meetingState) || !topology) return;

      const peers = remotePeers.length;
      const isSFU = topology === 'sfu';

      const videoSendSettings =
        typeof sendSettings?.video === 'object' ? sendSettings?.video : null;
      const lowP2PKbs = Math.floor(
        getP2PLowKbs(videoSendSettings) / Math.max(1, peers)
      );
      const highP2PKbs = Math.floor(
        getP2PHighKbs(videoSendSettings) / Math.max(1, peers)
      );
      const highLayer = getHighLayer(videoSendSettings);
      const lowLayer = getLowLayer(videoSendSettings);

      switch (q) {
        case 'auto':
        case 'high':
          if (isSFU) {
            if (lastSetMaxQuality.current !== highLayer) {
              updateSendSettings({
                video: {
                  maxQuality: highLayer,
                },
              });
              lastSetMaxQuality.current = highLayer;
            }
          } else {
            if (lastSetKBS.current !== highP2PKbs) {
              daily.setBandwidth({ kbs: highP2PKbs });
              lastSetKBS.current = highP2PKbs;
            }
          }
          break;
        case 'low':
        case 'bandwidth-saver':
          if (isSFU) {
            if (lastSetMaxQuality.current !== lowLayer) {
              updateSendSettings({
                video: {
                  maxQuality: lowLayer,
                },
              });
              lastSetMaxQuality.current = lowLayer;
            }
          } else {
            if (lastSetKBS.current !== lowP2PKbs) {
              daily.setBandwidth({
                kbs: lowP2PKbs,
              });
              lastSetKBS.current = lowP2PKbs;
            }
          }
          if (q === 'bandwidth-saver') daily.setLocalVideo(false);
          break;
      }
      lastQualitySet.current = q;
    },
    [
      daily,
      meetingState,
      remotePeers.length,
      sendSettings?.video,
      topology,
      updateSendSettings,
    ]
  );

  /**
   * Re-calculate bandwidth limits when network threshold or videoQuality changes.
   */
  useEffect(() => {
    const intendedQuality = getIntendedQuality({
      camMuteDisabled: disableCameraNetworkQualityMute.current,
      isSharingScreen,
      networkThreshold: threshold,
      qualitySetting: videoQuality,
    });
    setQuality(intendedQuality);
  }, [isSharingScreen, setQuality, threshold, videoQuality, topology]);
};
