import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { useCallState } from '/contexts/CallProvider';
import {
  useShowFullscreenButton,
  useShowLeaveButton,
  useShowNames,
  useShowParticipantsBar,
} from '/lib/state/custom';

/**
 * Helper hook to identify whether this call is running in an Intercom integration.
 * Automatically hides participant bar, names and leave button.
 */
export const useIntercomIntegration = () => {
  const { query } = useRouter();
  const { state } = useCallState();
  const [, setShowFullscreenButton] = useShowFullscreenButton();
  const [, setShowLeaveButton] = useShowLeaveButton();
  const [, setShowNames] = useShowNames();
  const [, setShowParticipantsBar] = useShowParticipantsBar();

  const configureForIntercom = useCallback(() => {
    setShowParticipantsBar(false);
    setShowNames(false);
    setShowLeaveButton(false);
    setShowFullscreenButton(true);
  }, [
    setShowFullscreenButton,
    setShowLeaveButton,
    setShowNames,
    setShowParticipantsBar,
  ]);

  useEffect(() => {
    if (
      query['autojoin'] === 't' &&
      query['intercomAppLink'] === 't' &&
      state === 'joining'
    ) {
      configureForIntercom();
    }
  }, [configureForIntercom, query, state]);
};
