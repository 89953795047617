export const getFocusableElements = (container: HTMLElement) => {
  if (!container) return [];
  return Array.from<HTMLElement>(
    container.querySelectorAll(
      'input,select,textarea,a[href],button,[tabindex],audio[controls],video[controls],[contenteditable]:not([contenteditable="false"])'
    )
  )
    .filter((el: HTMLElement) => el.getAttribute('disabled') === null)
    .filter((el: HTMLElement) => el.getAttribute('aria-disabled') !== 'true')
    .filter((el: HTMLElement) => {
      const tabindex = el.getAttribute('tabindex');
      return tabindex === null || parseInt(tabindex, 10) >= 0;
    });
};

export const scrollToElement = (targetEl: HTMLElement) => {
  const rect = targetEl.getBoundingClientRect();
  const headerHeight =
    document.documentElement.style.getPropertyValue('--header-height');
  window.scrollTo({
    behavior: 'smooth',
    top: Math.max(
      0,
      window.pageYOffset + rect.y - parseInt(headerHeight, 10) - 16
    ),
  });
  const id = targetEl.getAttribute('id');
  if (id) {
    history.pushState({}, '', `#${id}`);
    window.dispatchEvent(new Event('hashchange'));
  }
};

export const isElementVisibleInViewport = (e: HTMLElement) => {
  const { bottom, left, right, top } = e.getBoundingClientRect();

  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// See: https://htmldom.dev/check-if-an-element-is-visible-in-a-scrollable-container/
export const isElementVisibleInContainer = (
  e: HTMLElement,
  container: HTMLElement,
  visibility = 1
) => {
  const { bottom, height, top } = e.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const vis = Math.min(Math.max(visibility, 0), 1);
  const minVisibleHeight = height * vis;

  const visibleBottomEdge = Math.min(bottom, containerRect.bottom);
  const visibleTopEdge = Math.max(top, containerRect.top);
  const visibleHeight = visibleBottomEdge - visibleTopEdge;

  return visibleHeight >= minVisibleHeight;
};
