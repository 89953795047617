import classNames from 'classnames';
import React from 'react';

interface BaseProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  muted?: boolean;
  size?: number;
}

interface PropsWithTransition extends BaseProps {
  id: string;
}

const MicrophoneIconWithTransition: React.FC<
  React.PropsWithChildren<PropsWithTransition>
> = ({ color = 'currentColor', id, muted = false, size = 24, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <mask id={`micIconMask${id}`}>
          <rect x="0" y="0" width="24" height="24" fill="white" />
          <path
            className={classNames('strike', { muted })}
            d="M2.88 19L18.88 3"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </mask>
      </defs>
      <g mask={muted ? `url(#micIconMask${id})` : undefined}>
        <path
          fill={color}
          d="M12 3C10.3431 3 9 4.34315 9 6V12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12V6C15 4.34315 13.6569 3 12 3Z"
        />
        <path
          fill={color}
          d="M7.5 12C7.5 11.5858 7.16421 11.25 6.75 11.25C6.33579 11.25 6 11.5858 6 12C6 13.9175 6.62158 15.4436 7.73826 16.4858C8.67527 17.3603 9.90114 17.8386 11.25 17.9654V20.25C11.25 20.6642 11.5858 21 12 21C12.4142 21 12.75 20.6642 12.75 20.25V17.9654C14.0989 17.8386 15.3247 17.3603 16.2617 16.4858C17.3784 15.4436 18 13.9175 18 12C18 11.5858 17.6642 11.25 17.25 11.25C16.8358 11.25 16.5 11.5858 16.5 12C16.5 13.5825 15.9966 14.6814 15.2383 15.3892C14.4713 16.105 13.3583 16.5 12 16.5C10.6417 16.5 9.52867 16.105 8.76174 15.3892C8.00342 14.6814 7.5 13.5825 7.5 12Z"
        />
      </g>
      <path
        className={classNames('strike', { muted })}
        d="M4 20L20 4"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <style jsx>{`
        svg {
          height: ${size}px;
          width: ${size}px;
          display: block;
        }
        .strike {
          transform-origin: center;
          transform: scale(0);
          transition: transform 240ms ease;
        }
        .strike.muted {
          transform: scale(1);
        }
      `}</style>
    </svg>
  );
};

const MicrophoneIconStatic: React.FC<React.PropsWithChildren<BaseProps>> = ({
  color = 'currentColor',
  id,
  muted = false,
  size = 24,
  ...props
}) => {
  if (muted) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <path
          d="M7.99996 2C9.06523 2 9.93598 2.83285 9.99659 3.88293L5.99996 7.87956V4C5.99996 2.89543 6.89539 2 7.99996 2Z"
          fill={color}
        />
        <path
          d="M9.99996 6.70711L13.6868 3.02022C13.8821 2.82496 13.8821 2.50838 13.6868 2.31311C13.4916 2.11785 13.175 2.11785 12.9797 2.31311L2.31307 12.9798C2.11781 13.175 2.11781 13.4916 2.31307 13.6869C2.50833 13.8821 2.82492 13.8821 3.02018 13.6869L5.46367 11.2434C6.03955 11.667 6.74051 11.9055 7.49996 11.9769V13.5C7.49996 13.7761 7.72382 14 7.99996 14C8.2761 14 8.49996 13.7761 8.49996 13.5V11.9769C9.3992 11.8924 10.2164 11.5736 10.8411 10.9905C11.5856 10.2957 12 9.27833 12 8C12 7.72386 11.7761 7.5 11.5 7.5C11.2238 7.5 11 7.72386 11 8C11 9.05501 10.6643 9.78763 10.1588 10.2595C9.64751 10.7367 8.90551 11 7.99996 11C7.27926 11 6.66216 10.8332 6.18251 10.5246L6.98398 9.72309C7.28173 9.89904 7.62905 10 7.99996 10C9.10453 10 9.99996 9.10457 9.99996 8V6.70711Z"
          fill={color}
        />
        <path
          d="M5.07402 8.8055C5.02583 8.56097 4.99996 8.29291 4.99996 8C4.99996 7.72386 4.7761 7.5 4.49996 7.5C4.22382 7.5 3.99996 7.72386 3.99996 8C3.99996 8.59793 4.09062 9.13877 4.26331 9.61621L5.07402 8.8055Z"
          fill={color}
        />
        <style jsx>{`
          svg {
            height: ${size}px;
            width: ${size}px;
            display: block;
          }
        `}</style>
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.89543 2 6 2.89543 6 4V8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8V4C10 2.89543 9.10457 2 8 2ZM5 8C5 7.72386 4.77614 7.5 4.5 7.5C4.22386 7.5 4 7.72386 4 8C4 9.27833 4.41438 10.2957 5.15884 10.9905C5.78352 11.5736 6.60076 11.8924 7.5 11.9769V13.5C7.5 13.7761 7.72386 14 8 14C8.27614 14 8.5 13.7761 8.5 13.5V11.9769C9.39924 11.8924 10.2165 11.5736 10.8412 10.9905C11.5856 10.2957 12 9.27833 12 8C12 7.72386 11.7761 7.5 11.5 7.5C11.2239 7.5 11 7.72386 11 8C11 9.05501 10.6644 9.78763 10.1588 10.2595C9.64755 10.7367 8.90556 11 8 11C7.09444 11 6.35245 10.7367 5.84116 10.2595C5.33562 9.78763 5 9.05501 5 8Z"
        fill={color}
      />
      <style jsx>{`
        svg {
          height: ${size}px;
          width: ${size}px;
          display: block;
        }
      `}</style>
    </svg>
  );
};

export const MicrophoneIcon: React.FC<
  React.PropsWithChildren<
    BaseProps | (PropsWithTransition & { transition: boolean })
  >
> = (props) => {
  if ('transition' in props) {
    const { transition, ...rest } = props;
    return <MicrophoneIconWithTransition {...rest} />;
  }
  return <MicrophoneIconStatic {...props} />;
};
