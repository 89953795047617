import getConfig from 'next/config';
import { useCallback, useMemo } from 'react';

import { useEnableJoinSound } from '/lib/state/custom';

import { useSound } from './useSound';

export const useSoundLoader = () => {
  const { assetPrefix } = getConfig().publicRuntimeConfig;
  const [enableJoinSound] = useEnableJoinSound();

  const isJoinSoundMuted = useCallback(
    () => !enableJoinSound,
    [enableJoinSound]
  );

  const errorSound = useSound(`${assetPrefix}/error.mp3`);
  const knockSound = useSound(`${assetPrefix}/knock.mp3`);
  const joinSound = useSound(`${assetPrefix}/join.mp3`, isJoinSoundMuted);

  const load = useCallback(() => {
    errorSound.load();
    knockSound.load();
    joinSound.load();
  }, [errorSound, knockSound, joinSound]);

  return useMemo(
    () => ({ errorSound, knockSound, joinSound, load }),
    [errorSound, knockSound, joinSound, load]
  );
};
